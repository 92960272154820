import React from 'react';
import styled from 'styled-components';

const MantPage = styled.div`


width:100%;
height: 100vh;

position:relative;

`;


const MantBox = styled.div`

position: absolute;
transform: translate(-50%, -50%);

top: 50%;
left: 50%;

background-color: var(--box);
border-radius: var(--big-border);

padding: 20px;

box-shadow: var(--box-shadow-1);

`

export default () => {

  return (
    
    <>
      <MantPage>
          <MantBox>
            <div style={{display: `flex`, justifyContent: `center`}}><div><img src="https://cdn.discordapp.com/attachments/879977094950559794/1188893092946911372/VasalloConstructor.png?ex=659c2df9&is=6589b8f9&hm=780a69c7b782cc54aa3ae6daca07e8bc9634532c88784e9f3c1f32fb6f86f815&" alt="" /></div></div>
            <div style={{textAlign: "center", fontSize: "24px", fontWeight: `700`}}>En Construcción</div>
            <div style={{textAlign: "center", fontSize: "18px", fontWeight: `500`}}>Estamos preparando todo para que la diversión regrese lo antes posible, por favor espera un poco mas.</div>
            
          </MantBox>
        </MantPage>
    </>

  );
};
