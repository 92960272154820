import React, { useEffect, useState } from 'react';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import TypeBadget from './TypeBadget';
import axios from 'axios';
import AttackCard from './AttackCard';

const Box = styled.div`

    background-color: var(--box);
    padding: 12px;
    border-radius: var(--big-border);

    display:grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 10px;
    grid-auto-rows: minmax(100px, auto);

    width: calc(100% - 34px);

    box-shadow: var(--box-shadow-1);

    transition: var(--transition-1);

`

const ImgBox = styled.div`
    
    & img {
        max-width: 25vw;
    }

    grid-column: 1;
    grid-row: 1;

    text-align: center;
    align-items: center;

`

const InfoBox = styled.div`

    & .title {
        font-size: 24px;
        font-weight: 700;
    }

    & .subtitle {
        font-size: 18px;
        font-weight: 500;
    }

    & .type {
        display: flex;
        flex-direction: row;
    }
    grid-column: 2;
    grid-row: 1;

`

const AttacksBox = styled.div`

    display: flex;
    gap: 20px;
    flex-direction: row;
    flex-wrap: wrap;

    grid-column: 3 / 5;
    grid-row: 1;

`


export default () => {

    const { id } = useParams();

    const [pokemon, setPokemon] = useState<any>();

    useEffect(() => {

        axios.get(`https://api.mrclaau.es/pk/data/${id}`).then((data) => {
            setPokemon(data.data);
        })

    }, [window.location.href]);


    return (

        <>

            {!pokemon ? (
                <></>
            ) : (
                <>
                    <Box>
                        <ImgBox>
                            <img src={pokemon.img} />
                        </ImgBox>
                        <InfoBox>
                            <div className='title'>{pokemon.mote}</div>
                            <div className='subtitle'>#{pokemon.number} {pokemon.name}</div>
                            <div className='level'><span style={{ fontWeight: 600 }}>Nivel:</span> {pokemon.level}</div>
                            <div className='type'>
                                {pokemon.type.map((type: any, indes: any) => (
                                    <TypeBadget name={type} />
                                ))}
                            </div>
                        </InfoBox>
                        <AttacksBox>
                            {pokemon.moves.map((type: any, indes: any) => (
                                <AttackCard attackInfo={type} />
                            ))}
                        </AttacksBox>
                    </Box>
                </>
            )}
        </>

    );
};
