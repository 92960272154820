import React from 'react';
import styled from 'styled-components';

const NavBar = styled.nav`

  background-color: var(--box);

  height: 95vh;
  width: 350px;

  margin-top: 2.5vh;
  margin-left: 10px;
  margin-right: 10px;

  border-radius: var(--big-border);

  box-shadow: var(--box-shadow-1);


`

export default () => {

  return (
    
    <>
      <NavBar>

      </NavBar>
    </>

  );
};
