import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import styled from 'styled-components';
import NavbarBig from './components/navbar/BigNavbar';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Mantenimiento from './pages/mantenimiento/Mantenimiento';
import Index from './pages/index/Index';
import PokemonMain from './pages/pokemon/PokemonMain';
import TeamView from './pages/pokemon/TeamView';
import BoxView from './pages/pokemon/BoxView';
import DownView from './pages/pokemon/DownView';
import PokemonStats from './pages/pokemon/PokemonStats';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const AllContent = styled.div`

  display: flex;
  flex-wrap

`

const PageContent = styled.div`

  width:100%;
  height: 100vh;
  overflow-y: auto;

`

const MantPage = styled.div`


width:100%;
height: 100vh;

position:relative;

`;


const MantBox = styled.div`

position: absolute;
transform: translate(-50%, -50%);

top: 50%;
left: 50%;

background-color: var(--box);
border-radius: var(--big-border);

padding: 20px;

box-shadow: var(--box-shadow-1);

`



root.render(
  <React.StrictMode>

    <AllContent>

      <NavbarBig />

      <PageContent>

        <BrowserRouter>
          <Routes>
            <Route index path="/" element={<Mantenimiento />} />
            <Route path="index" element={<Index />} />
            <Route path='pokemon' element={<PokemonMain />}>
              <Route index element={<TeamView />} />
              <Route path='guardados' element={<BoxView />} />
              <Route path='caidos' element={<DownView />} />
              <Route path='data/:id' element={<PokemonStats />} />
            </Route>
          </Routes>
        </BrowserRouter>

      </PageContent>

    </AllContent>

  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
