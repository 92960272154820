import React, { useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import TypeBadget from './TypeBadget';

const Box = styled.div`

    background-color: rgb(115, 117, 115);

    flex: 1 0 45%;

    max-width: calc(100% - 55%);
    
    color: #fff;

    padding: 12px;

    border-radius: var(--big-border);

    & .title {
        font-size: 20px;
        font-weight: 600;
        display: flex;
        gap: 20px;
        align-items: center;
    }

    & .stats {
        display: flex;
        justify-content: space-evenly;
    }

`

export default ({ attackInfo }: { attackInfo: any }) => {

    return (

        <>

            <Box>
                <div className='title'><div>{attackInfo.name}</div><TypeBadget name={attackInfo.type} /></div>
                <div className='stats'>
                    <div>Potencia: {attackInfo.power || 0}</div>
                    <div>Precisión: {attackInfo.accuracy || 100}</div>
                </div>
            </Box>

        </>

    );
};
