import React, { useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

const PokeNav = styled.div`

    margin-top: 20px;
    display: flex;
    justify-content: space-evenly;
    

`

const NavItem = styled.div`

    background-color: var(--box);

    padding: 8px 16px;

    box-shadow: var(--box-shadow-2);

    border-radius: var(--small-border);

    font-weight: 600;

    &.active {
        color: var(--primary-color) !important;
    }

    &:hover {
        color: var(--primary-color) !important;
    }

    cursor: pointer;

    transition: var(--transition-1);

`

const Container = styled.div`

    margin-top: 20px;

`

export default () => {

    useEffect(() => {

        const url = window.location.pathname;

        console.log(url);
        document.querySelector(`.team`)?.classList.remove(`active`);
        document.querySelector(`.box`)?.classList.remove(`active`);
        document.querySelector(`.down`)?.classList.remove(`active`);


        switch (url) {
            case `/pokemon`:
                document.querySelector(`.team`)?.classList.add(`active`);
                break;
            case `/pokemon/guardados`:
                document.querySelector(`.box`)?.classList.add(`active`);
                break;
            case `/pokemon/caidos`:
                document.querySelector(`.down`)?.classList.add(`active`);
                break;

            default:
                break;
        }


    }, [window.location.href])

    const navigate = useNavigate();

    function goToTeam() {
        navigate(`/pokemon`);
    }
    function goToBox() {
        navigate(`/pokemon/guardados`);
    }
    function goToDown() {
        navigate(`/pokemon/caidos`);
    }

    return (

        <>

            <PokeNav>
                <NavItem className='team' onClick={goToTeam}>Equipo</NavItem>
                <NavItem className='box' onClick={goToBox}>Guardados</NavItem>
                <NavItem className='down' onClick={goToDown}>Caidos</NavItem>
            </PokeNav>

            <Container>
                <Outlet />
            </Container>

        </>

    );
};
