import React, { useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import TypeBadget from './TypeBadget';

const Box = styled.div`

    background-color: var(--box);
    padding: 12px;
    border-radius: var(--big-border);

    display:flex;
    flex-direction: row;

    flex: 1 0 45%;

    max-width: calc(100% - 55%);

    box-shadow: var(--box-shadow-1);

    cursor: pointer;

    &:hover .title {
        color: var(--primary-color);
        transition: var(--transition-2);
    }

    transition: var(--transition-1);

`

const ImgBox = styled.div`

    max-width: 128px;
    
    & img {
        max-width: 100px;
    }

`

const InfoBox = styled.div`

    & .title {
        font-size: 18px;
        font-weight: 700;
    }

    & .subtitle {
        font-size: 12px;
        font-weight: 500;
    }

    & .type {
        display: flex;
        flex-direction: row;
    }

`


export default ({pokeData}: {pokeData:any}) => {

    console.log(pokeData);

    const navigate = useNavigate();

    function goToStats(){
        navigate(`/pokemon/data/${pokeData.id}`)
    }
    

    return (

        <>
            <Box onClick={goToStats}>
                <ImgBox>
                    <img src={pokeData.img} />
                </ImgBox>
                <InfoBox>
                    <div className='title'>{pokeData.name}</div>
                    <div className='subtitle'>#{pokeData.number} {pokeData.pokemon}</div>
                    <div className='level'><span style={{fontWeight: 600}}>Nivel:</span> {pokeData.level}</div>
                    <div className='type'>
                        {pokeData.type.map((type:any, indes:any) => (
                            <TypeBadget name={type} />
                        ))}
                    </div>
                </InfoBox>
            </Box>
        </>

    );
};
