import React, { useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

const Badget = styled.div`

    color: #fff;
    background: rgb(115, 117, 115);
    text-align: center;
    border-style: solid;
    border-width: 3px;
    border-radius: 6px;
    color: #fff;
    padding: 2px;

`

const BadgetName = styled.div`

    padding-left: 10px;
    padding-right: 10px;

`

export default ({ name }: { name: string }) => {

    let color = `#333`;
    let bgColor = `hsl(0 0% 100% / .2);`;

    switch (name) {
        case `Normal`:
            color = `rgb(198, 80, 0);`
            bgColor = `#9fa19f`;
            break;
        case `Lucha`:
            color = `rgb(115, 117, 115);`
            bgColor = `#ff8000`;
            break;
        case `Volador`:
            color = `rgb(61, 122, 172);`
            bgColor = `#81b9ef`;
            break;
        case `Veneno`:
            color = `rgb(117, 38, 175)`
            bgColor = `#9141cb;`;
            break;
        case `Tierra`:
            color = `rgb(117, 57, 8)`
            bgColor = `#915121`;
            break;
        case `Roca`:
            color = `rgb(125, 120, 82)`
            bgColor = `#afa981`;
            break;
        case `Bicho`:
            color = `rgb(107, 125, 0)`
            bgColor = `#91a119`;
            break;
        case `Acero`:
            color = `rgb(60, 127, 149)`
            bgColor = `#60a1b8`;
            break;
        case `Fuego`:
            color = `rgb(198, 0, 19)`
            bgColor = `#e62829`;
            break;
        case `Agua`:
            color = `rgb(0, 103, 210)`
            bgColor = `#2980ef`;
            break;
        case `Planta`:
            color = `rgb(28, 134, 5)`
            bgColor = `#3fa129`;
            break;
        case `Eléctrico`:
            color = `rgb(154, 108, 0)`
            bgColor = `#fac000`;
            break;
        case `Psíquico`:
            color = `rgb(208, 25, 96)`
            bgColor = `#ef4179`;
            break;
        case `Hielo`:
            color = `rgb(0, 126, 163)`
            bgColor = `#3fd8ff`;
            break;
        case `Dragón`:
            color = `rgb(45, 72, 196)`
            bgColor = `#5060e1`;
            break;
        case `Siniestro`:
            color = `rgb(57, 43, 41)`
            bgColor = `#50413f`;
            break;
        case `Hada`:
            color = `rgb(189, 63, 191)`
            bgColor = `##ef70ef`;
            break;
        case `Fantasma`:
            color = `rgb(87, 42, 87)`
            bgColor = `#704170`;
            break;

        default:
            break;
    }


    return (

        <>

            <Badget style={{ backgroundColor: color, borderColor: bgColor }}>
                <BadgetName>
                    {name}
                </BadgetName>
            </Badget>

        </>

    );
};
