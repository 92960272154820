import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import styled from 'styled-components';
import PokemonCard from './PokemonCard';


const PokemonContainer = styled.div`

    display: flex;
    flex-direction: row;
    gap: 20px;
    flex-wrap: wrap;

`

export default () => {

    const [pokemon, setPokemon] = useState<any>()

    useEffect(() => {
        axios.get(`https://api.mrclaau.es/pk/caja/1ae4df51`).then((data) => {
            console.log(data);
            setPokemon(data.data)
        })
    }, [window.location.href])

    return (

        <>

            {(!pokemon) ? (
                <></>
            ) : (
                <>
                    {(pokemon.length > 0) ? (
                        <PokemonContainer>
                            {pokemon.map((pokeData: any, index: any) => (
                                <PokemonCard pokeData={pokemon[index]} />
                            ))}
                        </PokemonContainer>
                    ) : (
                        <>No hay ningun pokemon aquí.</>
                    )}

                </>
            )}

        </>

    );
};
