import React from 'react';
import styled from 'styled-components';
import AnnouncesBox from '../../components/index/AnnouncesBox';

const Container = styled.div`

position: relative;
width: calc(100% - 10px);

`

const Banner = styled.div`

    width:100%;
    height: 350px;

    margin-top: 20px;

    border-radius: var(--big-border);
    box-shadow: var(--box-shadow-1);

    position:relative;

    background-image: url("https://cdn.discordapp.com/attachments/879977094950559794/1188943107526496328/Banner_MrClau_PURWorks.png?ex=659c5c8e&is=6589e78e&hm=c2016c4e5ebcfe446ae906d0ff685bb588366e3a3d91921f913c8539bebb80cf&"); /* The image used */
    background-color: #cccccc;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    display: flex;
    justify-content: center;

`;

export default () => {

    return (

        <>
            <Container>
                <Banner>
                    
                </Banner>
            </Container>
            <Container>
                
            </Container>
        </>

    );
};
